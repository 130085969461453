<template>
  <!-- password:data START -->
  <div class="password-data">
    <div class="description">
      <p v-html="`${$t('login.registerpassword')}`"></p>
    </div>

    <!-- form:register:form:password START -->
    <el-form-item prop="password">
      <el-input
        :type="type"
        :placeholder="`${$t('login.password')} *`"
        v-model="registerFormChild.password"
        @keyup.enter.native="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
        autocomplete="off"
      >
        <i
          slot="suffix"
          class="el-icon-view"
          v-on:click="showHidePassword()"
        ></i>
      </el-input>
    </el-form-item>
    <!-- form:register:form:password END -->

    <!-- form:register:form:confirm-password START -->
    <el-form-item prop="confirmPassword">
      <el-input
        :type="type"
        :placeholder="`${$t('login.confirmpassword')} *`"
        v-model="registerFormChild.confirmPassword"
        @keyup.enter.native="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
        autocomplete="off"
      >
        <i
          slot="suffix"
          class="el-icon-view"
          v-on:click="showHidePassword()"
        ></i>
      </el-input>
    </el-form-item>
    <!-- form:register:form:confirm-password END -->

    <!-- form:register:form:validate & calculate START -->
    <el-form-item>
      <el-button
        class="btn-default"
        type="primary"
        v-on:click="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      >{{ $t('login.continue') }}</el-button>

      <p
        class="fake-btn-default"
        v-on:click="backStep(progressActiveIndexChild = -1)"
      >{{ $t('login.back') }}</p>
    </el-form-item>
    <!-- form:register:form:validate & calculate END -->
  </div>
  <!-- password:data END -->
</template>

<script>
export default {
  name: 'password-data',
  props: {
    registerForm: {
      type: Object,
    },

    validateStep: {
      type: Function,
    },

    executeRecaptcha: {
      type: Function,
    },

    backStep: {
      type: Function,
    },

    globalErrorMessage: {
      type: Boolean,
    },

    type: {
      type: String,
      default: 'password',
    },

    showHidePassword: {
      type: Function,
    },

    progressActiveIndex: {
      type: Number,
    },
  },
  data() {
    return {
      registerFormChild: this.registerForm,
      progressActiveIndexChild: this.progressActiveIndex,
    };
  },
  mounted() {
    this.validateStep();

    this.backStep();
  },
};
</script>
